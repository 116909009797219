<template>
    <div class="page">
        <SideBarHeader
            :isActive="sideBarHeader"
            @hideSideBarHeader="sideBarHeader = false"
        />

        <main class="main">
            <section class="face-section">
                <div class="page__box-1">
                    <HeaderAdaptive @showSideBarHeader="sideBarHeader = true" />
                </div>

                <div class="face-section__box-1">
                    <div class="container container_header face-section__box-11">
                        <Header page="main" />
                    </div>

                    <div class="container face-section__box-12">
                        <h1 class="face-section__title">
                            {{ $t("land.views.land.face.title") }}
                        </h1>
                        <h2 class="face-section__subtitle">
                            {{ $t("land.views.land.face.subtitle") }}
                        </h2>

                        <form class="face-section__box-121">
                            <div class="custom-input">
                                <svg
                                    class="icon icon_medium custom-input__icon"
                                >
                                    <use
                                        href="@/assets/icons/sprites.svg#country"
                                    />
                                </svg>
                                <Select
                                    :data="selectData"
                                    :selectedOption="selectSelectedOption"
                                    @updateSelectedOption="
                                        selectUpdateSelectedOption
                                    "
                                />
                            </div>

                            <div class="face-section__decor"></div>

                            <div class="custom-input">
                                <svg
                                    class="icon icon_medium custom-input__icon"
                                >
                                    <use
                                        href="@/assets/icons/sprites.svg#date"
                                    />
                                </svg>
                                <v-date-picker
                                    v-model="date"
                                    :locale="$t('language')"
                                    mode="date"
                                    :valid-hours="{ min: 7, max: 23 }"
                                    :minute-increment="15"
                                    :min-date="new Date()"
                                    is24hr
                                >
                                    <template
                                        v-slot="{ inputValue, inputEvents }"
                                    >
                                        <input
                                            class="date-picker-input px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                                            :value="inputValue"
                                            :placeholder="
                                                $t(
                                                    'global.components.UI.input.date.placeholder'
                                                )
                                            "
                                            v-on="inputEvents"
                                        />
                                    </template>
                                </v-date-picker>
                            </div>
                            <button class="btn face-section__btn">
                                <h1 class="face-section__btn-display-name">
                                    Search
                                </h1>
                            </button>
                        </form>

                        <h2 class="face-section__text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem quae error autem nostrum reiciendis totam, ratione rerum laudantium accusamus aperiam ipsa unde nihil vero commodi dolorum fuga. Aspernatur unde aliquam veritatis beatae optio delectus nesciunt? Velit eligendi veniam soluta eum!</h2>
                    </div>
                </div>

                <div class="face-section__box-2">
                    <div class="face-section__box-22">
                        <img
                            src="@/assets/images/land/auto2.svg"
                            alt=""
                            class="face-section__img"
                        />
                    </div>
                </div>
            </section>

            <section class="partners">
                <img
                    src="@/assets/images/land/carvertical.png"
                    alt=""
                    class="partners__img"
                />
                <img
                    src="@/assets/images/land/tec-doc.png"
                    alt=""
                    class="partners__img"
                />
                <img
                    src="@/assets/images/land/endado.png"
                    alt=""
                    class="partners__img"
                />
                <img
                    src="@/assets/images/land/beedigital.png"
                    alt=""
                    class="partners__img"
                />
                <img
                    src="@/assets/images/land/generali.png"
                    alt=""
                    class="partners__img"
                />
            </section>

            <section class="container section-1">
                <div class="section-1__box-1">
                    <h1 class="section-1__title">
                        {{ $t("land.views.land.section-1.title") }}
                    </h1>
                    <h2 class="section-1__subtitle">
                        {{ $t("land.views.land.section-1.text") }}
                    </h2>

                    <a
                        href="https://crm.getpits.com/business/auth/sign-in"
                        target="_blank"
                        class="btn section-1__btn"
                        >Find Out Now</a
                    >
                </div>
                <div class="section-1__box-2"></div>
            </section>

            <section class="container section-2">
                <h1 class="section-2__title">
                    {{ $t("land.views.land.section-2.title") }}
                </h1>
                <h2 class="section-2__subtitle">
                    {{ $t("land.views.land.section-2.subtitle") }}
                </h2>

                <div class="section-2__box-1">
                    <div class="section-2__item">
                        <div class="section-2__item-box-1">
                            <svg class="section-2__item-icon">
                                <use
                                    href="@/assets/icons/sprites.svg#find-your-workshop"
                                />
                            </svg>
                        </div>
                        <h1 class="section-2__item-title">
                            {{ $t("land.views.land.section-2.item-1.title") }}
                        </h1>
                        <p class="section-2__item-text">
                            {{ $t("land.views.land.section-2.item-1.text") }}
                        </p>
                    </div>
                    <div class="section-2__item">
                        <div class="section-2__item-box-1">
                            <svg class="section-2__item-icon">
                                <use href="@/assets/icons/sprites.svg#pickup" />
                            </svg>
                        </div>
                        <h1 class="section-2__item-title">
                            {{ $t("land.views.land.section-2.item-2.title") }}
                        </h1>
                        <p class="section-2__item-text">
                            {{ $t("land.views.land.section-2.item-2.text") }}
                        </p>
                    </div>
                    <div class="section-2__item">
                        <div class="section-2__item-box-1">
                            <svg class="section-2__item-icon">
                                <use
                                    href="@/assets/icons/sprites.svg#tracking"
                                />
                            </svg>
                        </div>
                        <h1 class="section-2__item-title">
                            {{ $t("land.views.land.section-2.item-3.title") }}
                        </h1>
                        <p class="section-2__item-text">
                            {{ $t("land.views.land.section-2.item-3.text") }}
                        </p>
                    </div>
                    <div class="section-2__item">
                        <div class="section-2__item-box-1">
                            <img
                                src="@/assets/icons/logo2.svg"
                                alt=""
                                class="section-2__item-icon"
                            />
                        </div>
                        <h1 class="section-2__item-title">
                            {{ $t("land.views.land.section-2.item-4.title") }}
                        </h1>
                        <p class="section-2__item-text">
                            {{ $t("land.views.land.section-2.item-4.text") }}
                        </p>
                    </div>
                </div>
            </section>

            <section class="container section-3">
                <div class="section-3__box-1"></div>
                <div class="section-3__box-2">
                    <h2 class="section-3__subtitle">
                        {{ $t("land.views.land.section-3.subtitle") }}
                    </h2>
                    <h1 class="section-3__title">
                        {{ $t("land.views.land.section-3.title") }}
                    </h1>
                    <p class="section-3__text-1">
                        {{ $t("land.views.land.section-3.text-1") }}
                    </p>

                    <div class="section-3__box-21">
                        <div class="section-3__item">
                            <svg class="section-3__item-icon">
                                <use
                                    href="@/assets/icons/sprites.svg#message"
                                />
                            </svg>
                            <div class="section-3__item-box-1">
                                <h1 class="section-3__item-title">
                                    {{
                                        $t(
                                            "land.views.land.section-3.item-1.title"
                                        )
                                    }}
                                </h1>
                                <h2 class="section-3__item-subtitle">
                                    {{
                                        $t(
                                            "land.views.land.section-3.item-1.text"
                                        )
                                    }}
                                </h2>
                            </div>
                        </div>
                        <div class="section-3__item">
                            <svg class="section-3__item-icon">
                                <use
                                    href="@/assets/icons/sprites.svg#message"
                                />
                            </svg>
                            <div class="section-3__item-box-1">
                                <h1 class="section-3__item-title">
                                    {{
                                        $t(
                                            "land.views.land.section-3.item-2.title"
                                        )
                                    }}
                                </h1>
                                <h2 class="section-3__item-subtitle">
                                    {{
                                        $t(
                                            "land.views.land.section-3.item-2.text"
                                        )
                                    }}
                                </h2>
                            </div>
                        </div>
                        <div class="section-3__item">
                            <svg class="section-3__item-icon">
                                <use
                                    href="@/assets/icons/sprites.svg#message"
                                />
                            </svg>
                            <div class="section-3__item-box-1">
                                <h1 class="section-3__item-title">
                                    {{
                                        $t(
                                            "land.views.land.section-3.item-3.title"
                                        )
                                    }}
                                </h1>
                                <h2 class="section-3__item-subtitle">
                                    {{
                                        $t(
                                            "land.views.land.section-3.item-3.text"
                                        )
                                    }}
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="container section-4">
                <h1 class="section-4__title">
                    {{ $t("land.views.land.section-4.title") }}
                </h1>
                <h2 class="section-4__subtitle">
                    {{ $t("land.views.land.section-4.subtitle") }}
                </h2>

                <router-link to="/search-map" class="btn section-4__btn"
                    >Send Request</router-link
                >
            </section>
        </main>

        <Footer />
    </div>
</template>

<script>
import { useI18n } from "vue-i18n";

import Header from "@/components/HeaderComp.vue";
import HeaderAdaptive from "@/components/HeaderAdaptiveComp.vue";

import Footer from "@/components/FooterComp.vue";

import Select from "@/components/UI/land/CustomSelect.vue";

import SideBarHeader from "@/components/SideBarHeaderComp.vue";



export default {
    components: {
        Header,
        HeaderAdaptive,
        Footer,
        Select,
        SideBarHeader,
    },
    data() {
        return {
            sideBarHeader: false,

            selectData: [
                {
                    id: 0,
                    name: "country",
                    displayName: "Country",
                },
                {
                    id: 1,
                    name: "us",
                    displayName: "US",
                },
                {
                    id: 2,
                    name: "portugal",
                    displayName: "Portugal",
                },
                {
                    id: 3,
                    name: "andorra",
                    displayName: "Andorra",
                },
            ],
            selectSelectedOption: {
                id: 0,
                name: "country",
                displayName: "Country",
            },

            date: new Date(localStorage.getItem('date') ? localStorage.getItem('date') : ''),
        };
    },
    watch: {
        date: function () {
            localStorage.setItem("date", this.date);
        },
    },
    methods: {
        selectUpdateSelectedOption(selectedOption) {
            this.selectSelectedOption = selectedOption;
        },
    },
    setup() {
        const { t } = useI18n();
        return { t };
    },
};
</script>

<style lang="sass" scoped>
@import @/assets/stylesheets/mixins
@import @/assets/stylesheets/variables

.page__box-1
    display: none

    @include mediaW($xxl)
        display: block

.container
    padding-right: 128px
    padding-left: 128px

    @include mediaW($lg)
        padding-right: 64px
        padding-left: 64px

    @include mediaW($mobile-l)
        padding-right: 32px
        padding-left: 32px

    @include mediaW($mobile-m)
        padding-right: 24px
        padding-left: 24px

    @include mediaW($mobile-s)
        padding-right: 16px
        padding-left: 16px

    &.container_header
        padding-right: 48px
        padding-left: 48px

        @include mediaW($mobile-s)
            padding-right: 16px
            padding-left: 16px

.main
    flex-direction: column

.face-section
    position: relative

    min-height: 100vh

    display: flex
    flex-direction: column

    justify-content: space-between

.face-section__bg-video
    position: absolute

    top: 0
    left: 0

    width: 100%
    height: 100%

    z-index: -1

.face-section__box-1
    padding-bottom: 32px

.face-section__box-11
    margin-bottom: 96px

    @include mediaW($xxl)
        display: none

.face-section__box-12
    display: flex
    flex-direction: column

    justify-content: center

.face-section__box-121
    margin-bottom: 64px

    display: flex

    align-items: center

    box-shadow: 0 5px 10px rgba(#C1544D, .15)

    border: 4px solid #C1544D
    border-radius: 10px

    @include mediaW($md)
        flex-direction: column

    @include mediaW($mobile-s)
        // flex-direction: column

.face-section__box-2
    display: flex
    flex-direction: column

    justify-content: space-between

.custom-input
    width: 100%

    position: relative

.date-picker-input,
.custom-input__input
    padding: 15px 15px 15px 70px

    font-size: 23.75px

.custom-input__icon
    position: absolute

    top: 50%
    left: 16px

    transform: translate(0, -50%)

    width: 24px
    height: 24px

    fill: #394B5E

.face-section__btn
    padding: 20px 40px

    color: #fff
    background-color: #C1544D

    // border-radius: 0 10px 10px 0

    @include mediaW($md)
        width: 100%

.face-section__btn-display-name
    font-size: 22.8px

.face-section__title
    margin-bottom: 32px

    font-size: 48px
    font-weight: 600

    @include mediaW($mobile-s)
        font-size: 40px

.face-section__subtitle
    margin-bottom: 64px

    font-size: 20px

    @include mediaW($mobile-s)
        margin-bottom: 64px

        font-size: 18px

.face-section__text
    font-size: 20px

    @include mediaW($mobile-s)
        font-size: 18px

.face-section__img
    // margin-bottom: -55px

    positin: relative

    width: 100%
    height: 384px

    @include mediaW($mobile-s)
        // margin-bottom: -37px

        height: 256px

.partners
    padding: 32px
    // padding-top: 32px
    // padding-bottom: 32px

    display: flex

    align-items: center
    justify-content: center

    column-gap: 20px

    @include mediaW($md)
        flex-direction: column

.partners__img
    width: 100%
    max-width: 224px
    height: auto

    @include mediaW($xxl)
        max-width: 192px

    @include mediaW($xl)
        max-width: 160px

    @include mediaW($lg)
        max-width: 112px

    @include mediaW($md)
        max-width: 256px

    // @include mediaW($md)
        // max-width: 312px

.section-1
    padding-top: 128px
    padding-bottom: 128px

    display: grid
    grid-template-columns: 1.5fr 1fr

    background-color: #f7f7f8

    @include mediaW($md)
        padding-top: 96px
        padding-bottom: 96px

    @include mediaW($mobile-s)
        padding-top: 64px
        padding-bottom: 64px

.section-1__box-1
    display: flex
    flex-direction: column

    align-items: flex-start

.section-1__title
    margin-bottom: 32px

    font-size: 32px
    font-weight: 500

    text-transform: uppercase

.section-1__subtitle
    margin-bottom: 48px

    font-size: 18px

.section-1__btn
    padding: 16px 48px

    font-size: 18px

    text-transform: uppercase

    color: #fff
    background-color: #394B5E
    box-shadow: 0 5px 10px rgba(#394B5E, .25)

    border-radius: 10px

.section-2
    padding-top: 128px
    padding-bottom: 128px

    display: flex
    flex-direction: column

    align-items: center

    @include mediaW($md)
        padding-top: 64px
        padding-bottom: 64px

    @include mediaW($mobile-s)
        padding-top: 64px
        padding-bottom: 64px

.section-2__box-1
    width: 100%

    display: grid
    grid-template-columns: 1fr 1fr

    gap: 64px

    @include mediaW($xl)
        grid-template-columns: 1fr

        gap: 32px

.section-2__title
    margin-bottom: 16px

    font-size: 28px
    font-weight: 500

.section-2__subtitle
    margin-bottom: 64px

    font-size: 20px

    text-transform: uppercase

.section-2__item
    padding: 32px

    display: flex
    flex-direction: column

    align-items: flex-start

    background-color: #fff
    box-shadow: 0 5px 10px rgba(#000, .15)

    border-radius: 16px

.section-2__item-box-1
    margin-bottom: 24px
    padding: 24px

    background-color: #efe9e2

    border-radius: 50%

.section-2__item-icon
    width: 48px
    height: 48px

    fill: #394B5E

.section-2__item-title
    margin-bottom: 32px

    font-size: 24px
    font-weight: 500

    text-transform: uppercase

.section-2__item-text
    font-size: 18px

.section-3
    padding-top: 64px
    padding-bottom: 64px

    display: grid
    grid-template-columns: 1fr 1fr

    background-color: #f7f7f8

    @include mediaW($md)
        padding-top: 96px
        padding-bottom: 96px

        grid-template-columns: 1fr

    @include mediaW($mobile-s)
        padding-top: 64px
        // padding-right: 16px
        padding-bottom: 64px
        // padding-left: 16px

.section-3__box-21
    display: flex
    flex-direction: column

    row-gap: 32px

.section-3__title
    margin-bottom: 24px

    font-size: 28px
    font-weight: 500

.section-3__subtitle
    margin-bottom: 8px

    font-size: 20px
    font-weight: 500

    text-transform: uppercase

.section-3__text-1
    margin-bottom: 48px

    font-size: 18px

.section-3__text-2
    margin-bottom: 48px

    font-size: 18px

.section-3__item
    display: flex

    align-items: flex-start

    column-gap: 16px

.section-3__item-box-1
    display: flex
    flex-direction: column

    row-gap: 8px

.section-3__item-icon
    min-width: 48px
    width: 48px
    min-height: 48px
    height: 48px

.section-3__item-title
    font-size: 20px
    font-weight: 500

.section-3__item-subtitle
    font-size: 18px

.section-4
    padding-top: 128px
    padding-bottom: 128px

    display: flex
    flex-direction: column

    align-items: center

    @include mediaW($md)
        padding-top: 96px
        padding-bottom: 96px

    @include mediaW($mobile-s)
        padding-top: 64px
        padding-bottom: 64px

.section-4__title
    margin-bottom: 24px

    font-size: 28px
    font-weight: 500

.section-4__subtitle
    margin-bottom: 32px

    font-size: 24px

.section-4__btn
    padding: 16px 48px

    font-size: 18px

    text-transform: uppercase

    color: #000
    background-color: #efe9e2
    box-shadow: 0 5px 10px rgba(#efe9e2, .25)

    border-radius: 10px

.face-section__decor
    width: 2px
    height: 48px

    background-color: #C1544D

    @include mediaW($md)
        display: none
</style>
