<template>
  <div class="custom-select" @blur="isActive = false" :tabindex="tabindex">
    <div @click="isActive = !isActive" class="custom-select__selected-option">
      <div class="custom-select__selected-option-inner">
        <svg class="custom-select__selected-option-icon">
          <use v-if="selectedOption === 'en'" href="@/assets/icons/sprites.svg#flag-en" />
          <use v-else-if="selectedOption === 'de'" href="@/assets/icons/sprites.svg#flag-de" />
          <use v-else-if="selectedOption === 'es'" href="@/assets/icons/sprites.svg#flag-es" />
          <use v-else-if="selectedOption === 'fr'" href="@/assets/icons/sprites.svg#flag-fr" />
          <use v-else-if="selectedOption === 'gr'" href="@/assets/icons/sprites.svg#flag-gr" />
          <use v-else-if="selectedOption === 'it'" href="@/assets/icons/sprites.svg#flag-it" />
          <use v-else href="@/assets/icons/sprites.svg#flag-en" />
        </svg>
        <h1 v-if="!selectedOption" class="custom-select__selected-option-display-name">
          EN
        </h1>
        <h1 v-else class="custom-select__selected-option-display-name">
          {{ selectedOption }}
        </h1>
      </div>
      <div class="custom-select__selected-option-box-1">
        <!-- icon or something else here -->
      </div>
    </div>

    <div
      class="custom-select__box-1"
      :class="{ 'custom-select__box-1_active': isActive }"
    >
      <div
        v-for="vQ in data"
        :key="vQ.id"
        @click="switchLang(vQ.name)"
        class="custom-select__option"
      >
        <div class="custom-select__option-inner">
          <svg class="custom-select__option-icon">
            <use
              v-if="vQ.name === 'en'"
              href="@/assets/icons/sprites.svg#flag-en"
            />
            <use
              v-else-if="vQ.name === 'de'"
              href="@/assets/icons/sprites.svg#flag-de"
            />
            <use
              v-else-if="vQ.name === 'es'"
              href="@/assets/icons/sprites.svg#flag-es"
            />
            <use
              v-else-if="vQ.name === 'fr'"
              href="@/assets/icons/sprites.svg#flag-fr"
            />
            <use
              v-else-if="vQ.name === 'gr'"
              href="@/assets/icons/sprites.svg#flag-gr"
            />
            <use
              v-else-if="vQ.name === 'it'"
              href="@/assets/icons/sprites.svg#flag-it"
            />
            <use v-else href="@/assets/icons/sprites.svg#flag-en" />
          </svg>
          <h1 class="custom-select__option-display-name">
            {{ vQ.displayName }}
          </h1>
        </div>
        <div class="custom-select__option-box-1">
          <!-- icon or something else here -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

import { ref } from "vue";

export default {
  props: {
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      data: [
        {
          id: 0,
          name: "en",
          displayName: "EN",
        },
        {
          id: 1,
          name: "de",
          displayName: "DE",
        },
        {
          id: 2,
          name: "es",
          displayName: "ES",
        },
        {
          id: 3,
          name: "fr",
          displayName: "FR",
        },
        {
          id: 4,
          name: "gr",
          displayName: "GR",
        },
        {
          id: 5,
          name: "it",
          displayName: "IT",
        },
      ],
    };
  },
  setup() {
    const { t, locale } = useI18n();

    let isActive = ref(false)
    let selectedOption = ref(localStorage.getItem("pitsLanguage"));

    const switchLang = (lang) => {
      locale.value = lang;
      localStorage.setItem("pitsLanguage", locale.value);

      isActive.value = false
      selectedOption.value = locale.value;
    };

    return { t, isActive, selectedOption, switchLang };
  },
};
</script>

<style lang="sass" scoped>
.custom-select
  position: relative

  cursor: pointer

.custom-select__box-1
  padding: 16px 24px

  position: absolute

  top: 48px
  left: 0

  width: 100%
  max-height: 0px

  display: flex
  flex-direction: column

  row-gap: 16px

  background-color: #fff

  border: 1px solid rgba(#000, .25)

  border-radius: 10px

  visibility: hidden
  opacity: 0

  transition: max-height .5s ease-in-out, visibility .5s ease-in-out, opacity .5s ease-in-out

  overflow: hidden

  z-index: 85

  &.custom-select__box-1_active
    max-height: 768px

    visibility: visible
    opacity: 1

.custom-select__selected-option
  padding: 8px 24px

  min-width: 112px

  display: flex
  align-items: center

  justify-content: center

  border: 1px solid rgba(#000, .25)

  border-radius: 10px

  z-index: 80

.custom-select__selected-option-inner
  display: flex

  align-items: center

  column-gap: 16px

.custom-select__selected-option-icon
  min-width: 18px
  width: 18px
  min-height: 18px
  height: 18px

  border-radius: 50%

.custom-select__selected-option-display-name
  font-size: 18px

  text-transform: uppercase

.custom-select__option
  display: flex

.custom-select__option-inner
  display: flex

  align-items: center

  column-gap: 16px

.custom-select__option-icon
  min-width: 18px
  width: 18px
  min-height: 18px
  height: 18px

  border-radius: 50%

.custom-select__option-display-name
  font-size: 18px
</style>
