<template>
    <header class="header" :class="{ header_active: isActive }">
        <div class="header__box-1">
            <div @click="$emit('hideSideBarHeader')" class="header__icon-box">
                <svg class="header__icon">
                    <use href="@/assets/icons/sprites.svg#cross" />
                </svg>
            </div>
            <router-link to="/" class="logo">
                <img src="@/assets/images/logo.svg" alt="" class="logo__img" />
            </router-link>
        </div>

        <div class="header__box-2">
            <Nav v-if="page === 'main'" />
            <form v-else class="search">
                <Select
                    :data="selectData"
                    :selectedOption="selectSelectedOption"
                    @updateSelectedOption="selectUpdateSelectedOption"
                />
                <div class="search__separator"></div>
                <div class="date">
                    <v-date-picker
                        v-model="date"
                        :locale="$t('language')"
                        mode="date"
                        :valid-hours="{ min: 7, max: 23 }"
                        :minute-increment="15"
                        :min-date="new Date()"
                        is24hr
                    >
                        <template v-slot="{ inputValue, inputEvents }">
                            <input
                                class="date-picker-input px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                                :value="inputValue"
                                :placeholder="
                                    $t(
                                        'global.components.UI.input.date.placeholder'
                                    )
                                "
                                v-on="inputEvents"
                            />
                        </template>
                    </v-date-picker>
                </div>

                <button class="btn search__btn">
                    <svg class="search__btn-icon">
                        <use href="@/assets/icons/sprites.svg#search" />
                    </svg>
                </button>
            </form>
        </div>

        <div class="header__box-3">
            <div class="header__box-31">
                <LanguageSwitcher :tabindex="0" />
            </div>
            <div class="header__box-32">
                <div class="header__box-321">
                    <a
                        href="http://crm.getpits.com/clients/auth/sign-in"
                        class="header-auth"
                    >
                        <svg class="header-auth__icon">
                            <use href="@/assets/icons/sprites.svg#user" />
                        </svg>
                        <h1 class="header-auth__display-name">Sign In</h1>
                    </a>
                </div>
                <div class="header__box-321">
                    <a
                        href="http://crm.getpits.com/business/auth/sign-in"
                        class="header-business"
                    >
                        <h1 class="header-business__display-name">
                            For Business
                        </h1>
                        <svg class="header-business__icon">
                            <use
                                href="@/assets/icons/sprites.svg#arrow-right"
                            />
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import Nav from "@/components/NavComp.vue";

import Select from "@/components/UI/land/CustomSelect2.vue";

import LanguageSwitcher from "@/components/UI/LanguageSwitcher.vue";

export default {
    components: {
        Nav,
        Select,
        LanguageSwitcher,
    },
    props: {
        isActive: Boolean,
    },
    data() {
        return {
            selectData: [
                {
                    id: 0,
                    name: "country",
                    displayName: "Country",
                },
                {
                    id: 1,
                    name: "us",
                    displayName: "US",
                },
                {
                    id: 2,
                    name: "portugal",
                    displayName: "Portugal",
                },
                {
                    id: 3,
                    name: "andorra",
                    displayName: "Andorra",
                },
            ],
            selectSelectedOption: {
                id: 0,
                name: "country",
                displayName: "Country",
            },
        }
    },
    methods: {
        selectUpdateSelectedOption(selectedOption) {
            this.selectSelectedOption = selectedOption
        }
    },
    setup() {
        //
    },
};
</script>

<style lang="sass" scoped>
@import @/assets/stylesheets/mixins
@import @/assets/stylesheets/variables

.header
    padding: 16px

    position: fixed

    top: 0
    left: -640px

    width: 320px
    height: 100vh

    display: flex
    flex-direction: column

    background-color: #fff
    box-shadow: 0 5px 10px rgba(#000, .25)

    transition: left .5s ease-in-out

    z-index: 100

    &.header_active
        left: 0

.header__box-1,
.header__box-2,
.header__box-3
    display: flex

.header__box-1
    margin-bottom: 64px

    align-items: center

    column-gap: 32px

.header__box-2
    margin-bottom: 32px

    justify-content: center

.header__box-3
    display: flex
    flex-direction: column

    // align-items: center
    justify-content: flex-end

    row-gap: 32px

.header__box-32
    display: flex

    align-items: center
    justify-content: space-between

.logo__img
    height: 80px

    @include mediaW($mobile-l)
        height: 40px

.header-auth
    display: flex

    align-items: center

    column-gap: 8px

.header-auth__icon
    width: 18px
    height: 18px

.header-auth__display-name
    font-size: 18px

    white-space: nowrap

.header-business
    padding: 12px 16px

    display: flex

    align-items: center

    column-gap: 16px

    background-color: #394B5E

    border-radius: 10px

.header-business__icon
    width: 18px
    height: 18px

    fill: #fff

.header-business__display-name
    font-size: 18px
    font-weight: 600

    color: #fff

    white-space: nowrap

.search
    width: 100%

    display: flex
    flex-direction: column

    align-items: center

    column-gap: 8px

    border: 1px solid rgba(#000, .25)
    border-radius: 10px

    @include mediaW($xxl)
        align-items: flex-start

.search__separator
    width: 1px
    height: 50%

    background-color: rgba(#000, .25)

.custom-input
    position: relative

    @include mediaW($mobile-s)
        max-width: none

.custom-input__icon
    position: absolute

    top: 50%
    left: 16px

    transform: translate(0, -50%)

    width: 16px
    height: 16px

.date-picker-input,
.custom-input__input
    // padding: 12px 24px 12px 48px
    // padding: 12px 24px
    padding: 12px 12px 12px 24px

    font-size: 16px

.search__btn
    padding: 15px

    width: 100%

    background-color: #C1544D

    border-radius: 10px

.search__btn-icon
    width: 16px
    height: 16px

    fill: #fff


.select
    // padding: 10.075px 13px 10.075px 13px

    position: relative

    min-width: 140px

    display: flex
    flex-direction: column

    // border: 1px solid #E4E6EF
    // border-radius: 8.45px

    cursor: pointer

    &.select_active
        .select__options
            display: flex
            flex-direction: column

.select__selected-option
    padding: 8px 16px

    display: flex

    align-items: center
    justify-content: space-between

    column-gap: 16px

    background-color: #fff

    border: 1px solid #efe9e2
    border-radius: 10px

    cursor: pointer

.select__selected-option-icon
    width: 10px
    height: 10px

.select__options
    padding: 13px 0

    position: absolute

    top: 44px
    left: 0

    width: 100%

    display: none

    background-color: #fff
    box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15)

    border-radius: 8.45px

    z-index: 1000

.select__option
    padding: 9.75px 16.25px

    display: flex

    align-items: center
    justify-content: space-between

    font-size: 13px

    color: #5e6278

    transition: color .25s ease-in-out, background-color .25s ease-in-out

    cursor: pointer

    &:hover
        color: #009ef7
        background-color: #f4f6fa

.select__option-icon
    width: 9.75px
    height: 9.75px

    display: none

    fill: #009ef7

.header-switch-language
    padding: 8px 16px

    display: flex

    align-items: center

    column-gap: 16px

    background-color: #fff

    border: 1px solid #efe9e2
    border-radius: 10px

    cursor: pointer

.select__selected-option-inner,
.select__option-inner
    display: flex

    align-items: center

    column-gap: 16px

.select__selected-option-flag,
.select__option-inner-flag
    width: 20px
    height: 20px

    border-radius: 50%
.select__selected-option-display-name,
.select__option-inner-display-name
    font-size: 18px
    font-weight: 600

.header__icon-box
    cursor: pointer

.header__icon
    width: 24px
    height: 24px

    @include mediaW($mobile-l)
        width: 16px
        height: 16px
</style>
