<template>
    <footer class="container2 footer">
        <div class="footer__box-1">
            <router-link to="/" class="logo">
                <img src="@/assets/images/logo.svg" alt="" class="logo__img" />
            </router-link>
            <div class="footer__box-12">
                <p class="footer__text">support@getpits.com</p>
                <div class="footer-socials">
                    <a href="#" class="footer-socials__item">
                        <svg class="footer-socials__item-icon">
                            <use href="@/assets/icons/sprites.svg#facebook" />
                        </svg>
                    </a>
                    <a href="#" class="footer-socials__item">
                        <svg class="footer-socials__item-icon">
                            <use href="@/assets/icons/sprites.svg#instagram" />
                        </svg>
                    </a>
                    <a href="#" class="footer-socials__item">
                        <svg class="footer-socials__item-icon">
                            <use href="@/assets/icons/sprites.svg#twitter" />
                        </svg>
                    </a>
                </div>
            </div>
        </div>

        <div class="footer__box-2">
            <nav class="footer-nav">
                <!-- <h1 class="footer-nav__title">
                        {{ $t("land.views.land.footer.nav-1.title") }}
                    </h1> -->

                <div class="footer-nav__box-1">
                    <router-link to="/" class="footer-nav__item"
                        ><h1 class="footer-nav__item-display-name">
                            {{
                                $t(
                                    "land.views.land.footer.nav-1.item-1.display-name"
                                )
                            }}
                        </h1></router-link
                    >
                    <router-link to="/" class="footer-nav__item"
                        ><h1 class="footer-nav__item-display-name">
                            {{
                                $t(
                                    "land.views.land.footer.nav-1.item-2.display-name"
                                )
                            }}
                        </h1></router-link
                    >
                </div>
            </nav>
            <nav class="footer-nav">
                <!-- <h1 class="footer-nav__title">
                        {{ $t("land.views.land.footer.nav-2.title") }}
                    </h1> -->

                <div class="footer-nav__box-1">
                    <router-link to="/" class="footer-nav__item"
                        ><h1 class="footer-nav__item-display-name">
                            {{
                                $t(
                                    "land.views.land.footer.nav-2.item-1.display-name"
                                )
                            }}
                        </h1></router-link
                    >
                    <router-link to="/" class="footer-nav__item"
                        ><h1 class="footer-nav__item-display-name">
                            {{
                                $t(
                                    "land.views.land.footer.nav-2.item-2.display-name"
                                )
                            }}
                        </h1></router-link
                    >
                    <router-link to="/" class="footer-nav__item"
                        ><h1 class="footer-nav__item-display-name">
                            {{
                                $t(
                                    "land.views.land.footer.nav-2.item-3.display-name"
                                )
                            }}
                        </h1></router-link
                    >
                </div>
            </nav>
        </div>
    </footer>
</template>

<style lang="sass" scoped>
@import @/assets/stylesheets/mixins
@import @/assets/stylesheets/variables

.container2
    padding-right: 128px
    padding-left: 128px

    @include mediaW($lg)
        padding-right: 64px
        padding-left: 64px

    @include mediaW($mobile-l)
        padding-right: 32px
        padding-left: 32px

    @include mediaW($mobile-m)
        padding-right: 24px
        padding-left: 24px

    @include mediaW($mobile-s)
        padding-right: 16px
        padding-left: 16px

.footer
    padding-top: 64px
    padding-bottom: 64px

    display: grid
    grid-template-columns: 1fr 1.5fr

    column-gap: 256px

    background-color: #f7f7f8

    @include mediaW($xl)
        display: flex
        flex-direction: column

.footer__box-1
    display: flex

    column-gap: 96px

    @include mediaW($xl)
        margin-bottom: 64px

        justify-content: center

    @include mediaW($sm)
        flex-direction: column

        align-items: center

        row-gap: 32px

.footer__box-12
    display: flex
    flex-direction: column

    row-gap: 32px

.footer__box-2
    display: flex

    align-items: flex-start
    justify-content: space-around

    column-gap: 64px

    @include mediaW($xl)
        column-gap: 64px

    @include mediaW($md)
        display: grid
        grid-template-columns: 1fr 1fr

        gap: 48px

    @include mediaW($sm)
        grid-template-columns: 1fr

.logo__img
    height: 64px

.footer__text
    font-size: 20px
    font-weight: 300

.footer-nav
    display: flex
    flex-direction: column

.footer-nav__box-1
    display: flex
    flex-direction: column

    row-gap: 32px

    @include mediaW($xl)
        align-items: center

    @include mediaW($sm)
        align-items: center

.footer-nav__title
    margin-bottom: 32px

    font-size: 24px
    font-weight: 500

    text-transform: uppercase

.footer-nav__item-display-name
    font-size: 20px
    font-weight: 300

.section-3__box-1
    @include mediaW($md)
        display: none

.footer-socials
    display: flex

    justify-content: space-between

    column-gap: 32px

    @include mediaW($sm)
        justify-content: space-between

.footer-socials__item-icon
    width: 24px
    height: 24px

    fill: #394B5E
</style>
