import { createRouter, createWebHistory } from "vue-router";

import LandView from "@/views/land/LandView.vue";

const routes = [
    {
        path: "/",
        name: "land",
        component: LandView,
        meta: {
            title: 'GetPits | Main'
        }
    },
    {
        path: "/services",
        name: "services",
        component: () => import("@/views/main/ServicesView.vue"),
        meta: {
            title: 'GetPits | Services'
        }
    },
    {
        path: "/services/map",
        name: "services-map",
        component: () => import("@/views/main/ServicesMapView.vue"),
        meta: {
            title: 'GetPits | Services Map'
        }
    },
    {
        path: "/search-map",
        name: "search-map",
        component: () => import("@/views/main/SearchMapView.vue"),
        meta: {
            title: 'GetPits | Search Map'
        }
    },
    {
        path: "/autoservice/:id",
        name: "auto-service",
        component: () => import("@/views/main/AutoServiceView.vue"),
        meta: {
            title: 'GetPits | AutoService'
        }
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title}`
    next()
})

export default router;
