import de from './de.json'
import en from './en.json'
import es from './es.json'
import fr from './fr.json'
import gr from './gr.json'
import it from './it.json'

export const defaultLocale = 'en'

export const languages = {
    de,
    en,
    es,
    fr,
    gr,
    it,
}
