<template>
  <nav class="nav">
      <router-link v-for="vQ in data" :key="vQ.id" to="/" class="nav__item">
          <h1 class="nav__item-display-name">
              {{ vQ.displayName }}
          </h1>
      </router-link>
  </nav>
</template>

<script>
export default {
  data() {
      return {
          data: [
              {
                  id: 0,
                  name: "explore",
                  displayName: "Explore",
              },
              {
                  id: 1,
                  name: "services",
                  displayName: "Services",
              },
              {
                  id: 2,
                  name: "business",
                  displayName: "Business",
              },
          ],
      };
  },
  setup() {
      //
  },
};
</script>

<style lang="sass" scoped>
.nav
  display: flex
  align-items: center

  column-gap: 64px
  
.nav__item-display-name
  font-size: 18px
  font-weight: 500
</style>