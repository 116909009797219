<template>
    <div class="select" @blur="selectIsActive = false" :tabindex="tabindex">
        <div
            @click="selectIsActive = !selectIsActive"
            class="select__selected-option"
        >
            <div class="select__selected-option-box-1">
                <!-- icon or something -->
                <h1 class="select__selected-option-display-name">
                    {{ selectedOption.displayName }}
                </h1>
            </div>

            <div class="select__selected-option-box-2">
                <!-- icon or something -->
            </div>
        </div>

        <Transition name="select">
            <div v-if="selectIsActive" class="select__box-1">
                <div
                    v-for="vQ in data"
                    :key="vQ.id"
                    @click="selectOption(vQ)"
                    class="select__option"
                    :class="{ select__option_hidden: vQ.id === 0 }"
                >
                    <div class="select__option-box-1">
                        <!-- icon or something -->
                        <h1 class="select__option-display-name">
                            {{ vQ.displayName }}
                        </h1>
                    </div>
                    <div class="select__option-box-2">
                        <!-- icon or something -->
                    </div>
                </div>
            </div>
        </Transition>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            required: true,
        },
        selectedOption: {
            type: Object,
            required: true,
        },
        tabindex: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    data() {
        return {
            selectIsActive: false,
        };
    },
    methods: {
        selectOption(option) {
            this.selectIsActive = false;
            this.$emit("updateSelectedOption", option);
        },
    },
    setup() {
        //
    },
};
</script>

<style lang="sass" scoped>
@import @/assets/stylesheets/transitions

.select
    position: relative

    width: 100%

    outline: none

    cursor: pointer

.select__box-1
    padding: 12px 12px 12px 24px

    position: absolute

    top: 48px
    left: 0

    width: 100%

    display: flex
    flex-direction: column

    row-gap: 16px

    background-color: #fff
    box-shadow: 0 5px 10px rgba(#000, .25)

    border-radius: 10px

    overflow: hidden

    z-index: 100

.select__selected-option
    padding: 12px 24px

    display: flex
    align-items: center

.select__selected-option-display-name
    font-size: 16px

.select__option
    display: flex
    align-items: center

    &.select__option_hidden
        display: none

.select__option-display-name
    font-size: 16px
</style>
