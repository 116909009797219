<template>
    <header class="header">
        <div class="header__box-1">
            <div @click="$emit('showSideBarHeader')" class="header__icon-box">
                <svg class="header__icon">
                    <use href="@/assets/icons/sprites.svg#menu-burger" />
                </svg>
            </div>
        </div>
        <div class="header__box-2">
            <router-link to="/" class="logo">
                <img src="@/assets/images/logo.svg" alt="" class="logo__img" />
            </router-link>
        </div>
    </header>
</template>

<script>
export default {
    props: {
        page: String,
    },
    setup() {
        //
    },
};
</script>

<style lang="sass" scoped>
@import @/assets/stylesheets/mixins
@import @/assets/stylesheets/variables
    
.header
    padding-left: 16px

    display: flex

    align-items: center

    column-gap: 32px

    height: 112px

    @include mediaW($mobile-l)
        height: 80px

.header__box-1,
.header__box-2,
.header__box-3
    display: flex

.header__box-2
    justify-content: center

.header__box-3
    align-items: center
    justify-content: flex-end

    column-gap: 32px

.logo__img
    height: 80px

    @include mediaW($mobile-l)
        height: 40px

.header-auth
    display: flex

    align-items: center

    column-gap: 8px

.header-auth__icon
    width: 18px
    height: 18px

.header-auth__display-name
    font-size: 18px

    white-space: nowrap

.header-business
    padding: 12px 16px

    display: flex

    align-items: center

    column-gap: 16px

    background-color: #394B5E

    border-radius: 10px

.header-business__icon
    width: 18px
    height: 18px

    fill: #fff

.header-business__display-name
    font-size: 18px
    font-weight: 600

    color: #fff

    white-space: nowrap

.search
    display: flex

    align-items: center

    column-gap: 8px

    border: 1px solid rgba(#000, .25)
    border-radius: 10px

.search__separator
    width: 1px
    height: 50%

    background-color: rgba(#000, .25)

.custom-input
    position: relative

    max-width: 160px

.custom-input__icon
    position: absolute

    top: 50%
    left: 16px

    transform: translate(0, -50%)

    width: 16px
    height: 16px

.custom-input__input
    // padding: 12px 24px 12px 48px
    // padding: 12px 24px
    padding: 12px 12px 12px 24px

    font-size: 16px

.search__btn
    padding: 15px

    background-color: #C1544D

    border-radius: 10px

.search__btn-icon
    width: 16px
    height: 16px


.select
    // padding: 10.075px 13px 10.075px 13px

    position: relative

    min-width: 140px

    display: flex
    flex-direction: column

    // border: 1px solid #E4E6EF
    // border-radius: 8.45px

    cursor: pointer

    &.select_active
        .select__options
            display: flex
            flex-direction: column

.select__selected-option
    padding: 8px 16px

    display: flex

    align-items: center
    justify-content: space-between

    column-gap: 16px

    background-color: #fff

    border: 1px solid #efe9e2
    border-radius: 10px

    cursor: pointer

.select__selected-option-icon
    width: 10px
    height: 10px

.select__options
    padding: 13px 0

    position: absolute

    top: 44px
    left: 0

    width: 100%

    display: none

    background-color: #fff
    box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15)

    border-radius: 8.45px

    z-index: 1000

.select__option
    padding: 9.75px 16.25px

    display: flex

    align-items: center
    justify-content: space-between

    font-size: 13px

    color: #5e6278

    transition: color .25s ease-in-out, background-color .25s ease-in-out

    cursor: pointer

    &:hover
        color: #009ef7
        background-color: #f4f6fa

.select__option-icon
    width: 9.75px
    height: 9.75px

    display: none

    fill: #009ef7

.header-switch-language
    padding: 8px 16px

    display: flex

    align-items: center

    column-gap: 16px

    background-color: #fff

    border: 1px solid #efe9e2
    border-radius: 10px

    cursor: pointer

.select__selected-option-inner,
.select__option-inner
    display: flex

    align-items: center

    column-gap: 16px

.select__selected-option-flag,
.select__option-inner-flag
    width: 20px
    height: 20px

    border-radius: 50%
.select__selected-option-display-name,
.select__option-inner-display-name
    font-size: 18px
    font-weight: 600

.header__icon-box
    cursor: pointer

.header__icon
    width: 32px
    height: 32px

    @include mediaW($mobile-l)
        width: 16px
        height: 16px
</style>
